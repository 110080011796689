import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";

import { TagCategoriesService } from "@/services";
import type { TagCategory } from "@/types";

interface State {
  loadState: "idle" | "pending" | "finished" | "failed";
  tagCategories: TagCategory[];
}

export const useTagCategoriesStore = defineStore("tagCategories", () => {
  const state = reactive<State>({
    loadState: "idle",
    tagCategories: []
  });

  const tags = computed(() => {
    return state.tagCategories.reduce<string[]>((flattenedTags, tagCategory) => {
      return [...flattenedTags, ...tagCategory.tags];
    }, []);
  });

  async function load() {
    try {
      state.loadState = "pending";
      state.tagCategories = await TagCategoriesService.getTagCategories();
      state.loadState = "finished";
    } catch (error) {
      state.loadState = "failed";
      throw error;
    }
  }

  return {
    ...toRefs(state),
    load,
    tags
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTagCategoriesStore, import.meta.hot));
}
