import "@/base";

import * as Sentry from "@sentry/vue";
import { createApp } from "vue";

import App from "@/App.vue";
import { config } from "@/config";
import { pinia } from "@/stores";

const app = createApp(App).use(pinia);

if (config.sentry.enabled) {
  Sentry.init({
    app,
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    integrations: [
      Sentry.extraErrorDataIntegration({
        captureErrorCause: true
      }),
      Sentry.httpClientIntegration()
    ],
    sendDefaultPii: true,
    ignoreErrors: [
      "The user aborted a request",
      "AbortError", // Usually seen when a fetch request is aborted, often intentionally (e.g., via AbortController).
      "TypeError: Failed to fetch", // Indicates a network request made using fetch() failed. Can be due to network issues or server unavailability.
      "TypeError: Failed Request Error", // Similar to 'Failed to fetch', this indicates issues with network requests.
      "TypeError: Load failed", // Indicates a failure in loading a resource (e.g., script, image).
      "TypeError: Failed to fetch dynamically imported module" // Indicates a failure in loading a dynamically imported module. Usually happens in development when a module is not found.
    ]
  });
}

// Force preservation of whitespace in templates or adjacent buttons
// will touch each other. Buttons should probably be wrapped in a
// container element and styled instead.
app.config.compilerOptions.whitespace = "preserve";

document.querySelectorAll(".vue-app").forEach((el) => {
  app.mount(el);
});
