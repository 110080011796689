<script setup lang="ts">
import { computed } from "vue";

import MwSpinner from "@/components/MwSpinner.vue";

interface Props {
  isDirty?: boolean;
  loadState?: "idle" | "pending" | "finished" | "failed";
  saveState?: "idle" | "pending" | "finished" | "failed";
}

const props = withDefaults(defineProps<Props>(), {
  isDirty: false,
  loadState: "idle",
  saveState: "idle"
});

const isLoading = computed(() => props.loadState === "pending");
const isSaving = computed(() => props.saveState === "pending");
const didSave = computed(() => props.saveState === "finished");
</script>

<template>
  <div class="exercise-program-editor-footer">
    <div class="exercise-program-editor-footer__actions">
      <slot name="start" />
    </div>
    <div class="text-muted exercise-program-editor-footer__status">
      <template v-if="isLoading"><MwSpinner size="sm" />Indlæser...</template>
      <template v-else-if="isSaving"><MwSpinner size="sm" />Gemmer...</template>
      <template v-else-if="didSave && !isDirty">Alle ændringer er gemt</template>
    </div>
    <div class="exercise-program-editor-footer__actions">
      <slot name="end" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.exercise-program-editor-footer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr auto;
  place-items: center end;
  padding: 1rem;
  gap: 1rem;

  &__status {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 0.5rem;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    gap: 0.5rem;
  }
}
</style>
