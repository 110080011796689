import yn from "yn";

export const config = {
  api: {
    prefixUrl: `${import.meta.env.VITE_APP_API_PREFIX_URL}`
  },
  sentry: {
    enabled: yn(import.meta.env.VITE_APP_SENTRY_ENABLED, { default: false }),
    dsn: `${import.meta.env.VITE_APP_SENTRY_DSN}`,
    environment: `${import.meta.env.VITE_APP_SENTRY_ENVIRONMENT}`
  }
};
