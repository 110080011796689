<script setup lang="ts">
import { pick } from "lodash-es";
import { computed, reactive, watch } from "vue";

import { useScopedId } from "@/composables";
import type { BarometerTemplate, ExerciseProgram, ExerciseProgramFormData } from "@/types";

interface Props {
  exerciseProgram: ExerciseProgram;
  barometerTemplates?: BarometerTemplate[];
}

const props = withDefaults(defineProps<Props>(), {
  barometerTemplates: () => []
});

const emit = defineEmits<{
  change: [data: ExerciseProgramFormData];
}>();

const activeBarometerTemplates = computed(() => {
  return props.barometerTemplates.filter((barometerTemplate) => !barometerTemplate.archived);
});

const archivedBarometerTemplates = computed(() => {
  return props.barometerTemplates.filter((barometerTemplate) => barometerTemplate.archived);
});

const formData = reactive<ExerciseProgramFormData>(
  pick(props.exerciseProgram, [
    "title",
    "comment",
    "barometerTemplateId",
    "progrediateLocked",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ])
);

watch(formData, (data) => {
  emit("change", data);
});

watch(
  () => props.exerciseProgram,
  (exerciseProgram) => {
    Object.assign(
      formData,
      pick(exerciseProgram, [
        "title",
        "comment",
        "barometerTemplateId",
        "progrediateLocked",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ]) satisfies ExerciseProgramFormData
    );
  },
  { deep: true }
);

const onToggleDays = () => {
  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"] as const;

  const isChecked = days.every((day) => formData[day]);

  days.forEach((day) => (formData[day] = !isChecked));
};

const { id } = useScopedId<keyof ExerciseProgramFormData>();
</script>

<template>
  <form class="exercise-program-form" autocomplete="off" @submit.prevent>
    <div class="form-group mb-3">
      <label class="form-label" :for="id('title')">Titel</label>
      <input :id="id('title')" v-model="formData.title" type="text" class="form-control" />
    </div>
    <div class="form-group mb-3">
      <label class="form-label" :for="id('comment')">Kommentar</label>
      <textarea :id="id('comment')" v-model="formData.comment" type="text" rows="10" class="form-control"></textarea>
    </div>
    <div class="form-group mb-3">
      <label class="form-label" :for="id('barometerTemplateId')">Barometer</label>
      <select :id="id('barometerTemplateId')" v-model.number="formData.barometerTemplateId" class="form-select">
        <option
          v-for="barometerTemplate in activeBarometerTemplates"
          :key="barometerTemplate.id"
          :value="barometerTemplate.id"
        >
          {{ barometerTemplate.title }} <template v-if="barometerTemplate.archived">(Arkiveret)</template>
        </option>
        <optgroup v-if="archivedBarometerTemplates.length" label="Arkiverede barometre">
          <option
            v-for="barometerTemplate in archivedBarometerTemplates"
            :key="barometerTemplate.id"
            :value="barometerTemplate.id"
          >
            {{ barometerTemplate.title }} <template v-if="barometerTemplate.archived">(Arkiveret)</template>
          </option>
        </optgroup>
      </select>
    </div>
    <div class="form-group mb-3">
      <div class="form-check form-switch">
        <label :for="id('progrediateLocked')" class="form-check-label">
          Progression/regression låst for patienten
        </label>
        <input
          :id="id('progrediateLocked')"
          v-model="formData.progrediateLocked"
          class="form-check-input"
          type="checkbox"
          role="switch"
        />
      </div>
    </div>
    <fieldset>
      <label class="form-label" @click="onToggleDays()">Træningsdage</label>
      <div class="exercise-program-form__training-days">
        <div class="form-group mb-1">
          <div class="form-check form-switch">
            <label :for="id('monday')" class="form-check-label"> Mandag </label>
            <input
              :id="id('monday')"
              v-model="formData.monday"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
          </div>
        </div>
        <div class="form-group mb-1">
          <div class="form-check form-switch">
            <label :for="id('tuesday')" class="form-check-label"> Tirsdag </label>
            <input
              :id="id('tuesday')"
              v-model="formData.tuesday"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
          </div>
        </div>
        <div class="form-group mb-1">
          <div class="form-check form-switch">
            <label :for="id('wednesday')" class="form-check-label"> Onsdag </label>
            <input
              :id="id('wednesday')"
              v-model="formData.wednesday"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
          </div>
        </div>
        <div class="form-group mb-1">
          <div class="form-check form-switch">
            <label :for="id('thursday')" class="form-check-label"> Torsdag </label>
            <input
              :id="id('thursday')"
              v-model="formData.thursday"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
          </div>
        </div>
        <div class="form-group mb-1">
          <div class="form-check form-switch">
            <label :for="id('friday')" class="form-check-label"> Fredag </label>
            <input
              :id="id('friday')"
              v-model="formData.friday"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
          </div>
        </div>
        <div class="form-group mb-1">
          <div class="form-check form-switch">
            <label :for="id('saturday')" class="form-check-label"> Lørdag </label>
            <input
              :id="id('saturday')"
              v-model="formData.saturday"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
          </div>
        </div>
        <div class="form-group mb-1">
          <div class="form-check form-switch">
            <label :for="id('sunday')" class="form-check-label"> Søndag </label>
            <input
              :id="id('sunday')"
              v-model="formData.sunday"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<style lang="scss" scoped>
.exercise-program-form {
  &__training-days {
    display: grid;
    grid-template-columns: repeat(3, auto);
    max-width: 300px;
    padding: 4px;
    gap: 4px 16px;
    border-radius: 6px;
  }
}
</style>
