<script setup lang="ts">
import { computed, ref, toRef } from "vue";

import AssignedExercises from "@/components/AssignedExercises.vue";
import ExerciseCard from "@/components/ExerciseCard.vue";
import ExerciseFinder from "@/components/ExerciseFinder.vue";
import ExerciseLevels from "@/components/ExerciseLevels.vue";
import ExerciseProgramEditorFooter from "@/components/ExerciseProgramEditorFooter.vue";
import ExerciseProgramForm from "@/components/ExerciseProgramForm.vue";
import MwButton from "@/components/MwButton.vue";
import MwFullscreenSpinner from "@/components/MwFullscreenSpinner.vue";
import MwIcon from "@/components/MwIcon.vue";
import MwModal from "@/components/MwModal.vue";
import { useConfirmNavigation } from "@/composables";
import { vDraggable } from "@/directives";
import {
  useBarometerTemplatesStore,
  useExerciseEditorStore,
  useExerciseFinderStore,
  useExerciseLevelsStore,
  useExerciseProgramEditorStore,
  useExercisesStore,
  useTagCategoriesStore
} from "@/stores";
import type { Exercise, ExerciseFilter } from "@/types";
import ExerciseEditorModalView from "@/views/ExerciseEditorModalView.vue";

const props = defineProps<{
  id: number;
  redirectUrl?: string;
}>();

const exerciseProgramEditorStore = useExerciseProgramEditorStore();
const exercisesStore = useExercisesStore();
const exerciseEditorStore = useExerciseEditorStore();
const exerciseLevelsStore = useExerciseLevelsStore();
const barometerTemplatesStore = useBarometerTemplatesStore();
const tagCategoriesStore = useTagCategoriesStore();
const exerciseFinderStore = useExerciseFinderStore();

tagCategoriesStore.load();
barometerTemplatesStore.load();
exerciseProgramEditorStore.load(props.id).then(() => {
  exercisesStore.load({ patientId: exerciseProgramEditorStore.draft.patientId });
});

const isPending = computed(() => exerciseProgramEditorStore.isLoading || exerciseProgramEditorStore.isSaving);

const showProgramFormModal = ref(false);
const showExerciseEditorModal = ref(false);

const onAddExercise = () => {
  exerciseProgramEditorStore.addExercise();
};

const onAssignLevel = (exercise: Exercise) => {
  if (!exerciseProgramEditorStore.isValidPosition()) {
    exerciseProgramEditorStore.addExercise();
  }

  exerciseProgramEditorStore.assignLevel(exercise);

  if (!exerciseProgramEditorStore.selectNextLevel() && !exerciseProgramEditorStore.selectNextExercise()) {
    exerciseProgramEditorStore.addExercise();
  }
};

const onAssignLevelNewExercise = (exercise: Exercise) => {
  exerciseProgramEditorStore.addExercise();
  onAssignLevel(exercise);
};

const onSaveProgram = async () => {
  await exerciseProgramEditorStore.save();

  if (props.redirectUrl) {
    window.location.href = props.redirectUrl;
  }
};

const onEditProgram = () => {
  showProgramFormModal.value = true;
};

const onCreateExercise = () => {
  exerciseEditorStore.create({ patientId: exerciseProgramEditorStore.draft.patientId });
  showExerciseEditorModal.value = true;
};

const onEditExercise = (exercise: Exercise) => {
  exerciseEditorStore.load(exercise);
  showExerciseEditorModal.value = true;
};

const onFilterChange = (filter: ExerciseFilter | undefined) => {
  if (!filter) {
    exerciseFinderStore.reset();
    return;
  }

  exerciseFinderStore.search(filter);
};

useConfirmNavigation(toRef(exerciseProgramEditorStore, "isDirty"));
</script>

<template>
  <div class="exercise-program-editor-view" :inert="isPending">
    <ExerciseProgramForm
      class="exercise-program-editor-view__program-form"
      :exercise-program="exerciseProgramEditorStore.draft"
      :barometer-templates="barometerTemplatesStore.barometerTemplates"
      @change="(data) => exerciseProgramEditorStore.updateProgram(data)"
    />
    <div class="exercise-program-editor-view__exercise-finder">
      <ExerciseLevels
        v-if="exerciseLevelsStore.selectedExercise"
        v-slot="{ exercise }"
        :selected-exercise="exerciseLevelsStore.selectedExercise"
        :progressing-exercises="exerciseLevelsStore.progressingExercises"
        :regressing-exercises="exerciseLevelsStore.regressingExercises"
        @close="exerciseLevelsStore.clear()"
      >
        <ExerciseCard
          :key="exercise.id"
          v-draggable:exercise="{ payload: exercise }"
          :exercise="exercise"
          :is-added="exerciseProgramEditorStore.hasExercise(exercise.id)"
          @assign-level="(exercise) => onAssignLevel(exercise)"
          @assign-level-new-exercise="(exercise) => onAssignLevelNewExercise(exercise)"
          @edit="(exercise) => onEditExercise(exercise)"
          @favorite="(exercise) => exercisesStore.addFavorite(exercise.id)"
          @unfavorite="(exercise) => exercisesStore.removeFavorite(exercise.id)"
          @show-levels="(exercise) => exerciseLevelsStore.select(exercise)"
        />
      </ExerciseLevels>
      <ExerciseFinder
        v-else
        :is-loading="exerciseFinderStore.isSearching || exercisesStore.isLoading"
        :exercises="exerciseFinderStore.exercises"
        :limit="exerciseFinderStore.limit"
        :tag-categories="tagCategoriesStore.tagCategories"
        :disabled-tags="exerciseFinderStore.nonMatchingTags"
        :filter="exerciseFinderStore.filter"
        :scope="exercisesStore.scope"
        @filter-change="(filter) => onFilterChange(filter)"
      >
        <template #actions>
          <MwButton
            v-if="exerciseProgramEditorStore.draft.patientId"
            variant="success"
            size="sm"
            @click="onCreateExercise()"
          >
            <MwIcon name="plus" /> Opret personlig øvelse
          </MwButton>
        </template>
        <template #default="{ exercise }">
          <ExerciseCard
            :key="exercise.id"
            v-draggable:exercise="{ payload: exercise }"
            :exercise="exercise"
            :is-added="exerciseProgramEditorStore.hasExercise(exercise.id)"
            @assign-level="(exercise) => onAssignLevel(exercise)"
            @assign-level-new-exercise="(exercise) => onAssignLevelNewExercise(exercise)"
            @edit="(exercise) => onEditExercise(exercise)"
            @favorite="(exercise) => exercisesStore.addFavorite(exercise.id)"
            @unfavorite="(exercise) => exercisesStore.removeFavorite(exercise.id)"
            @show-levels="(exercise) => exerciseLevelsStore.select(exercise)"
          />
        </template>
      </ExerciseFinder>
    </div>

    <AssignedExercises
      class="exercise-program-editor-view__assigned-exercises"
      :assigned-exercises="exerciseProgramEditorStore.assignedExercises"
      :barometer-template="exerciseProgramEditorStore.barometerTemplate"
      :selected-position="exerciseProgramEditorStore.selectedPosition"
      @add-exercise="onAddExercise()"
      @update-exercise="({ data, exerciseIndex }) => exerciseProgramEditorStore.updateExercise(data, exerciseIndex)"
      @remove-exercise="(exerciseIndex) => exerciseProgramEditorStore.removeExercise(exerciseIndex)"
      @move-exercise="({ fromIndex, toIndex }) => exerciseProgramEditorStore.moveExercise(fromIndex, toIndex)"
      @select-level="(position) => exerciseProgramEditorStore.select(position)"
      @assign-level="({ exercise, position }) => exerciseProgramEditorStore.assignLevel(exercise, position)"
      @clear-level="(position) => exerciseProgramEditorStore.clearLevel(position)"
      @update-level="({ data, position }) => exerciseProgramEditorStore.updateLevel(data, position)"
      @move-level="({ fromPosition, toPosition }) => exerciseProgramEditorStore.moveLevel(fromPosition, toPosition)"
    />
    <ExerciseProgramEditorFooter
      class="exercise-program-editor-view__footer"
      :is-dirty="exerciseProgramEditorStore.isDirty"
      :load-state="exerciseProgramEditorStore.loadState"
      :save-state="exerciseProgramEditorStore.saveState"
    >
      <template #start>
        <MwButton v-if="props.redirectUrl" :href="props.redirectUrl" tag="a" outline variant="secondary">
          <MwIcon name="arrow-left-short" /> Tilbage
        </MwButton>
        <div class="exercise-program-editor-view__footer-divider"></div>
        <MwButton class="d-xxl-none" variant="secondary" @click="onEditProgram()">
          <MwIcon name="pencil-fill" /> Ret træningsprogram
        </MwButton>
      </template>
      <template #end>
        <MwButton
          outline
          :disabled="isPending || !exerciseProgramEditorStore.isDirty"
          variant="primary"
          @click="exerciseProgramEditorStore.save()"
        >
          Gem
        </MwButton>
        <MwButton
          :disabled="isPending || !exerciseProgramEditorStore.isDirty"
          variant="primary"
          @click="onSaveProgram()"
        >
          Gem & tilbage
        </MwButton>
      </template>
    </ExerciseProgramEditorFooter>
  </div>

  <MwFullscreenSpinner v-if="exerciseProgramEditorStore.isLoading" />

  <ExerciseEditorModalView v-if="showExerciseEditorModal" v-model="showExerciseEditorModal" />

  <MwModal v-if="showProgramFormModal" v-model="showProgramFormModal" title="Træningsprogram" size="lg">
    <template #default="{ close }">
      <ExerciseProgramForm
        :exercise-program="exerciseProgramEditorStore.draft"
        :barometer-templates="barometerTemplatesStore.barometerTemplates"
        @change="(data) => exerciseProgramEditorStore.updateProgram(data)"
        @submit="close()"
      />
    </template>
    <template #footer="{ close }">
      <MwButton variant="primary" @click="close()">OK</MwButton>
    </template>
  </MwModal>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.exercise-program-editor-view {
  display: grid;
  position: relative;
  grid-template-areas:
    "assigned-exercises"
    "exercise-finder"
    "footer";
  height: 100%;

  &__program-form {
    display: none;
    grid-area: program-form;
    padding: 1rem 1.5rem;
    background-color: #f8f9fa;
  }

  &__assigned-exercises {
    grid-area: assigned-exercises;
  }

  &__exercise-finder {
    grid-area: exercise-finder;
  }

  &__footer {
    position: sticky;
    z-index: 10;
    bottom: 0;
    grid-area: footer;
    border-top: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.1);
  }

  &__footer-divider {
    width: 1px;
    margin-inline: 0.5rem;
    background: #ddd;
  }
}

@include media-breakpoint-up(lg) {
  .exercise-program-editor-view {
    grid-template-areas:
      "assigned-exercises exercise-finder"
      "footer footer";
    grid-template-columns: minmax(700px, 1fr) 1fr;

    &__assigned-exercises {
      overflow-y: auto;
      overscroll-behavior-y: contain;
      scroll-padding-top: 1rem;
      scroll-padding-bottom: 1rem;
      border-right: 1px solid #ddd;
    }

    &__exercise-finder {
      overflow-y: auto;
      overscroll-behavior-y: contain;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .exercise-program-editor-view {
    grid-template-areas:
      "program-form assigned-exercises exercise-finder"
      "footer footer footer";
    grid-template-columns: fit-content(390px) 700px 1fr;
    grid-template-rows: minmax(0, 1fr) auto;

    &__program-form {
      display: unset;
      overflow-y: auto;
      overscroll-behavior-y: contain;
      border-right: 1px solid #ddd;
    }
  }
}
</style>
