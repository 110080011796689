<script setup lang="ts">
import MwButton from "@/components/MwButton.vue";
import MwModal from "@/components/MwModal.vue";
import type { Exercise } from "@/types";

interface Props {
  exercise: Exercise;
}

const props = defineProps<Props>();
</script>

<template>
  <MwModal :title="props.exercise.title" size="xl">
    <template #default>
      <div class="exercise-video-modal">
        <video
          class="exercise-video-modal__video"
          controls
          autoplay
          preload="auto"
          :poster="props.exercise.keyframeUrl"
        >
          <source :src="props.exercise.videoUrl" type="video/mp4" />
        </video>

        <div class="exercise-video-modal__description">
          {{ props.exercise.description }}
        </div>
      </div>
    </template>

    <template #footer="{ close }">
      <MwButton variant="primary" @click="close()">Luk</MwButton>
    </template>
  </MwModal>
</template>

<style lang="scss" scoped>
.exercise-video-modal {
  &__video {
    width: 100%;
    border-radius: 4px;
  }

  &__description {
    grid-area: description;
    white-space: pre-wrap;
  }
}
</style>
