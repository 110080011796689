import { acceptHMRUpdate, defineStore } from "pinia";
import { reactive, toRefs } from "vue";

import { BarometerTemplatesService } from "@/services";
import type { BarometerTemplate } from "@/types";

interface State {
  loadState: "idle" | "pending" | "finished" | "failed";
  barometerTemplates: BarometerTemplate[];
}

export const useBarometerTemplatesStore = defineStore("barometerTemplates", () => {
  const state = reactive<State>({
    loadState: "idle",
    barometerTemplates: []
  });

  async function load() {
    try {
      state.loadState = "pending";
      state.barometerTemplates = await BarometerTemplatesService.getBarometerTemplates();
      state.loadState = "finished";
    } catch (error) {
      state.loadState = "failed";
      throw error;
    }
  }

  function getBarometerTemplate(id: number) {
    return state.barometerTemplates.find((entity) => entity.id === id);
  }

  return {
    ...toRefs(state),
    load,
    getBarometerTemplate
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBarometerTemplatesStore, import.meta.hot));
}
