<script setup lang="ts">
import { computed } from "vue";

interface Props {
  tag?: "button" | "a";
  variant?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "link";
  outline?: boolean;
  size?: "sm" | "md" | "lg";
}

const props = withDefaults(defineProps<Props>(), {
  tag: "button",
  variant: "primary",
  outline: false,
  size: "md"
});

const buttonClasses = computed(() => {
  const variant = props.outline ? `outline-${props.variant}` : props.variant;

  return ["btn", `btn-${variant}`, `btn-${props.size}`];
});

const buttonAttrs = computed(() => {
  const attrs = {
    class: buttonClasses.value
  };

  if (props.tag === "button") {
    return { ...attrs, type: "button" };
  }

  return attrs;
});
</script>

<template>
  <Component :is="props.tag" v-bind="buttonAttrs" class="mw-button">
    <span class="mw-button__content">
      <slot />
    </span>
  </Component>
</template>

<style lang="scss" scoped>
.mw-button {
  &__content {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
  }
}
</style>
