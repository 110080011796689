<template>
  <button type="button" class="mw-image-button">
    <slot />
    <span class="mw-image-button__overlay">
      <slot name="overlay" />
    </span>
  </button>
</template>

<style lang="scss" scoped>
.mw-image-button {
  position: relative;
  padding: 0;
  overflow: hidden;
  border: none;
  outline: 0;
  background: none;

  &:slotted(> img) {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }

  &__overlay {
    display: none;
    position: absolute;
    inset: 0;
    background-color: rgba(#000, 0.3);
    pointer-events: none;
    place-items: center;

    &:slotted(> .mw-icon) {
      width: 4rem;
      height: 4rem;
      opacity: 0.9;
      color: #fff;
    }
  }

  &:is(:hover, :focus-visible) &__overlay {
    display: grid;
  }
}
</style>
