import camelcaseKeys from "camelcase-keys";
import decamelizeKeys from "decamelize-keys";
import queryString from "query-string";
import { CancellablePromise, Cancellation } from "real-cancellable-promise";

import { HttpStatusCode, UnexpectedResponseError, ValidationFailedResponseError } from "@/api";
import { config } from "@/config";

type RequestOptions = RequestInit & {
  prefixUrl?: string;
  queryParams?: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData?: Record<string, any>;
  json?: Record<string, unknown>;
};

function request<T>(url: string, options: RequestOptions): CancellablePromise<T> {
  const controller = new AbortController();

  options = {
    prefixUrl: config.api.prefixUrl,
    headers: { Accept: "application/json", ...options.headers },
    signal: controller.signal,
    ...options
  };

  if (options.queryParams) {
    const queryParams = queryString.stringify(
      decamelizeKeys(options.queryParams, {
        deep: true
      }),
      { arrayFormat: "bracket" }
    );

    url = `${url}?${queryParams}`;
  }

  if (options.formData) {
    const formData = new FormData();

    Object.entries(decamelizeKeys(options.formData)).forEach(([key, value]) => {
      if (value === undefined) {
        return;
      }

      if (value instanceof File) {
        formData.set(key, value);
        return;
      }

      if (value === null) {
        value = "";
      }

      formData.set(key, `${value}`);
    });

    options.body = formData;
  }

  if (options.json) {
    options.headers = { "Content-Type": "application/json", ...options.headers };
    options.body = JSON.stringify(decamelizeKeys(options.json, { deep: true }));
  }

  const promise = fetch(`${options.prefixUrl}${url}`, options)
    .then((response) => parseResponse(response))
    .then((json) => camelcaseKeys(json, { deep: true }) as T)
    .catch((error) => {
      if (error.name === "AbortError") {
        throw new Cancellation();
      }

      throw error;
    });

  return new CancellablePromise<T>(promise, () => {
    return controller.abort();
  });
}

async function parseResponse(response: Response): Promise<Record<string, unknown>> {
  switch (response.status) {
    case HttpStatusCode.NO_CONTENT:
      return {};
    case HttpStatusCode.UNPROCESSABLE_ENTITY:
      throw new ValidationFailedResponseError(response);
    default:
      if (!response.ok) {
        throw new UnexpectedResponseError(response);
      }
  }

  try {
    return await response.json();
  } catch (error) {
    if (error instanceof Error) {
      throw new UnexpectedResponseError(response, `Invalid JSON (${error.message || "Syntax Error"})`);
    }

    throw new UnexpectedResponseError(response, "Invalid JSON");
  }
}

export function get<T>(url: string, options?: RequestOptions): CancellablePromise<T> {
  return request(url, { ...options, method: "GET" });
}

export function post<T>(url: string, options?: RequestOptions): CancellablePromise<T> {
  return request(url, { ...options, method: "POST" });
}

export function put<T>(url: string, options?: RequestOptions): CancellablePromise<T> {
  return request(url, { ...options, method: "PUT" });
}

export function patch<T>(url: string, options?: RequestOptions): CancellablePromise<T> {
  return request(url, { ...options, method: "PATCH" });
}

export function del<T>(url: string, options?: RequestOptions): CancellablePromise<T> {
  return request(url, { ...options, method: "DELETE" });
}

export { CancellablePromise, Cancellation };
