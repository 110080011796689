import type { Ref } from "vue";
import { onBeforeUpdate, ref } from "vue";

type TemplateRefsList<T> = T[] & {
  set(el: unknown | null): void;
};

export function useTemplateRefsList<T = Element>(): Readonly<Ref<TemplateRefsList<T>>> {
  const refs = ref<unknown>([]) as Ref<TemplateRefsList<T>>;

  refs.value.set = (el: T) => {
    refs.value.push(el);
  };

  onBeforeUpdate(() => {
    refs.value.length = 0;
  });

  return refs;
}
