import type { CancellablePromise } from "@/api";

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type CancellablePromiseFn = (...args: any[]) => CancellablePromise<any>;

/**
 * Returns wrapped function that automatically aborts pending promises.
 */
export function useSingleton<F extends CancellablePromiseFn>(fn: F) {
  let promise: CancellablePromise<unknown> | undefined;

  return (...args: Parameters<F>): ReturnType<F> => {
    if (promise) {
      promise.cancel();
    }

    promise = fn(...args);
    return promise as ReturnType<F>;
  };
}
