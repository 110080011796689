import type { Directive } from "vue";

import { registerDraggable, unregisterDraggable, type UseDraggableConfig } from "@/composables";

export const vDraggable: Directive<HTMLElement, Partial<UseDraggableConfig>> = {
  mounted(element, { arg: type, value: options }) {
    registerDraggable(element, { type, ...options });
  },
  unmounted(el) {
    unregisterDraggable(el);
  }
};
