<script setup lang="ts">
import { computed, reactive, watch } from "vue";

import MwButton from "@/components/MwButton.vue";
import MwModal from "@/components/MwModal.vue";
import MwSpinner from "@/components/MwSpinner.vue";
import VideoTrimSelector from "@/components/VideoTrimSelector.vue";
import { useScopedId } from "@/composables";
import { defaultFilter, useExerciseEditorStore, useExerciseFinderStore } from "@/stores";
import type { ExerciseDto } from "@/types";

const exerciseEditorStore = useExerciseEditorStore();
const exerciseFinderStore = useExerciseFinderStore();

const formData = reactive<ExerciseDto>({ ...exerciseEditorStore.draft });

watch(
  () => exerciseEditorStore.draft,
  (exercise) => Object.assign(formData, { ...exercise }),
  { deep: true }
);

const videoUrl = computed(() => {
  if (formData.video instanceof Blob) {
    return URL.createObjectURL(formData.video);
  }

  if ("videoUrl" in formData) {
    return formData.videoUrl;
  }

  return undefined;
});

const onFileChange = (files: FileList | null) => {
  formData.video = files?.[0];
};

const onSubmit = async (exercise: ExerciseDto, closeModalFn: () => void) => {
  if (await exerciseEditorStore.save(exercise)) {
    exerciseFinderStore.search({
      ...defaultFilter,
      query: exercise.title,
      onlyPersonal: true
    });

    closeModalFn();
  }
};

const { id } = useScopedId<keyof ExerciseDto>();

const titleText = computed(() => {
  return exerciseEditorStore.isNew(exerciseEditorStore.draft) ? "Opret personlig øvelse" : "Rediger personlig øvelse";
});

const submitBtnText = computed(() => {
  return exerciseEditorStore.isNew(exerciseEditorStore.draft) ? "Opret" : "Gem";
});
</script>

<template>
  <MwModal :title="titleText" size="xl">
    <template #default="{ close }">
      <div class="exercise-editor-modal-view">
        <div v-if="exerciseEditorStore.errorMessage" class="text-danger mb-3">
          {{ exerciseEditorStore.errorMessage }}
        </div>
        <form
          autocomplete="off"
          class="exercise-editor-modal-view__form"
          :inert="exerciseEditorStore.isSaving"
          @submit.prevent="onSubmit(formData, close)"
        >
          <div>
            <div class="form-group mb-3">
              <label class="form-label" :for="id('title')">Titel</label>
              <input :id="id('title')" v-model="formData.title" type="text" class="form-control" />
            </div>
            <div class="form-group mb-3">
              <label class="form-label" :for="id('description')">Beskrivelse</label>
              <textarea
                :id="id('description')"
                v-model="formData.description"
                type="text"
                rows="3"
                class="form-control"
              ></textarea>
            </div>
            <div class="form-group mb-3">
              <label class="form-label" :for="id('equipment')">Nødvendigt udstyr</label>
              <textarea
                :id="id('equipment')"
                v-model="formData.equipment"
                type="text"
                rows="3"
                class="form-control"
              ></textarea>
            </div>
            <div class="form-group mb-3">
              <label class="form-label" :for="id('feeling')">Følelse</label>
              <textarea
                :id="id('feeling')"
                v-model="formData.feeling"
                type="text"
                rows="3"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div>
            <div class="form-group mb-3">
              <label class="form-label" :for="id('video')">Video</label>
              <input
                :id="id('video')"
                type="file"
                accept="video/*"
                capture="environment"
                class="form-control"
                @change="(event) => onFileChange((event.target as HTMLInputElement).files)"
              />
            </div>
            <VideoTrimSelector
              v-if="videoUrl"
              :video-url="videoUrl"
              @change="(selection) => exerciseEditorStore.setVideoTrim(selection)"
            />
          </div>
        </form>
      </div>
    </template>
    <template #footer="{ close }">
      <div class="exercise-editor-modal-view__footer">
        <div class="text-muted exercise-editor-modal-view__footer-status">
          <template v-if="exerciseEditorStore.isSaving"><MwSpinner size="sm" />Gemmer...</template>
        </div>
        <div class="text-muted exercise-editor-modal-view__footer-actions">
          <MwButton variant="secondary" outline @click="close()">Annuller</MwButton>
          <MwButton :disabled="exerciseEditorStore.isSaving" variant="primary" @click="onSubmit(formData, close)">
            {{ submitBtnText }}
          </MwButton>
        </div>
      </div>
    </template>
  </MwModal>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.exercise-editor-modal-view {
  &__form {
    display: grid;
  }

  &__footer {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;

    &-status {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      gap: 0.5rem;
    }

    &-actions {
      display: grid;
      grid-auto-flow: column;
      gap: 0.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .exercise-editor-modal-view {
    &__form {
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      gap: 1.5rem;
    }
  }
}
</style>
