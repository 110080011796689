import { pick } from "lodash-es";
import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";

import { ValidationFailedResponseError } from "@/api";
import { ExercisesService } from "@/services";
import { useExercisesStore } from "@/stores";
import type { CreateExerciseDto, Exercise, ExerciseDto, ExerciseScope, UpdateExerciseDto } from "@/types";

export interface TrimSelection {
  start: number;
  end: number;
}

interface State {
  saveState: "idle" | "pending" | "finished" | "failed";
  errorMessage: string | undefined;
  draft: ExerciseDto;
  videoTrim: TrimSelection | undefined;
}

export const useExerciseEditorStore = defineStore("exerciseEditor", () => {
  const state = reactive<State>({
    saveState: "idle",
    errorMessage: undefined,
    draft: {
      patientId: null,
      title: "",
      description: "",
      equipment: "",
      feeling: "",
      video: undefined
    },
    videoTrim: undefined
  });

  const exercisesStore = useExercisesStore();

  const isSaving = computed(() => state.saveState === "pending");

  function createDraft(exercise: Exercise): UpdateExerciseDto {
    return pick(exercise, ["id", "patientId", "title", "description", "equipment", "feeling", "videoUrl"]);
  }

  function isNew(data: ExerciseDto): data is CreateExerciseDto {
    return "id" in data === false;
  }

  function create(scope: ExerciseScope) {
    useExerciseEditorStore().$reset();
    Object.assign(state.draft, pick(scope, ["patientId"]));
  }

  function load(exercise: Exercise) {
    useExerciseEditorStore().$reset();
    state.draft = createDraft(exercise);
  }

  async function save(data = state.draft): Promise<boolean> {
    try {
      state.errorMessage = undefined;
      state.saveState = "pending";

      const exercise = isNew(data)
        ? await ExercisesService.createExercise(data)
        : await ExercisesService.updateExercise(data);

      if (state.videoTrim) {
        await ExercisesService.trimVideo(exercise, state.videoTrim);
      }

      await exercisesStore.loadOne(exercise.id);

      state.saveState = "finished";

      return true;
    } catch (error) {
      state.saveState = "failed";

      if (error instanceof ValidationFailedResponseError) {
        state.errorMessage = "Validering fejlede. Prøv igen.";
        return false;
      }

      throw error;
    }
  }

  function setVideoTrim(selection: TrimSelection | undefined) {
    state.videoTrim = selection;
  }

  return {
    ...toRefs(state),
    isSaving,
    create,
    isNew,
    load,
    save,
    setVideoTrim
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExerciseEditorStore, import.meta.hot));
}
