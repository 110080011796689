import type { CancellablePromise } from "real-cancellable-promise";

import { get, patch, post } from "@/api";
import type { TrimSelection } from "@/stores";
import type { CreateExerciseDto, Exercise, ExerciseFilter, ExerciseScope, UpdateExerciseDto } from "@/types";

export function getExercise(id: number): CancellablePromise<Exercise> {
  return get(`/exercises/${id}`);
}

export function getExercises(scope?: ExerciseScope): CancellablePromise<Exercise[]> {
  return get("/exercises", { queryParams: scope });
}

export function search(filter?: Partial<ExerciseFilter>, scope?: ExerciseScope): CancellablePromise<number[]> {
  return get("/exercises/search", { queryParams: { ...filter, ...scope } });
}

export function createExercise(exercise: CreateExerciseDto): CancellablePromise<Exercise> {
  return post("/exercises", { formData: exercise });
}

export function updateExercise(exercise: UpdateExerciseDto): CancellablePromise<Exercise> {
  return patch(`/exercises/${exercise.id}`, { formData: exercise });
}

export function trimVideo(exercise: Exercise, selection: TrimSelection): CancellablePromise<void> {
  return patch(`/exercises/${exercise.id}/trim_video`, {
    json: {
      patientId: exercise.patientId,
      start: selection.start,
      end: selection.end
    }
  });
}

export function favorite(id: number): CancellablePromise<void> {
  return patch(`/exercises/${id}/favorite`);
}

export function unfavorite(id: number): CancellablePromise<void> {
  return patch(`/exercises/${id}/unfavorite`);
}
