import { reactive, type Ref, toRefs, watch } from "vue";

export function useMediaControls(targetElement: Ref<HTMLMediaElement | undefined>) {
  const state = reactive({
    isPlaying: false,
    currentTime: 0,
    duration: NaN
  });

  const play = () => {
    targetElement.value?.play();
  };

  const pause = () => {
    targetElement.value?.pause();
  };

  const seekTo = (time: number) => {
    if (targetElement.value) {
      targetElement.value.currentTime = time;
    }
  };

  watch(targetElement, (element) => {
    element?.addEventListener("play", () => {
      state.isPlaying = true;
    });

    element?.addEventListener("pause", () => {
      state.isPlaying = false;
    });

    element?.addEventListener("timeupdate", (event) => {
      state.currentTime = (event.target as HTMLMediaElement).currentTime;
    });

    element?.addEventListener("durationchange", (event) => {
      state.duration = (event.target as HTMLMediaElement).duration;
    });
  });

  return {
    ...toRefs(state),
    play,
    pause,
    seekTo
  };
}
