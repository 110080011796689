<script setup lang="ts">
import "@medware/survey-web-form/dist/style.css";

import { type Questionnaire, SWFQuestionnaire } from "@medware/survey-web-form";
import { ref } from "vue";

const props = defineProps<{
  json: string;
  age: string;
  gender: string;
  mode: "display" | "interactive";
}>();

const questionnaire = ref<Questionnaire>({
  ...JSON.parse(props.json),
  age: props.age,
  gender: props.gender,
  filled_on_platform: "Web"
});
</script>

<template>
  <SWFQuestionnaire v-model:questionnaire="questionnaire" :mode="mode" show-colors show-as-clinician></SWFQuestionnaire>
</template>
