<script setup lang="ts">
import { pick } from "lodash-es";
import { reactive, watch } from "vue";

import MwButton from "@/components/MwButton.vue";
import MwModal from "@/components/MwModal.vue";
import { useScopedId } from "@/composables";
import type { AssignedExerciseLevel, AssignedExerciseLevelFormData, Exercise } from "@/types";

interface Props {
  level: AssignedExerciseLevel;
  exercise: Exercise;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  submit: [data: AssignedExerciseLevelFormData];
}>();

const formData = reactive<AssignedExerciseLevelFormData>(pick(props.level, ["title", "description", "comment"]));

watch(
  () => props.level,
  (level) => {
    Object.assign(formData, pick(level, ["title", "description", "comment"]) satisfies AssignedExerciseLevelFormData);
  },
  { deep: true }
);

const onSubmit = (data: AssignedExerciseLevelFormData, callbackFn?: () => void) => {
  emit("submit", data);
  callbackFn?.();
};

const { id } = useScopedId<keyof AssignedExerciseLevelFormData>();
</script>

<template>
  <MwModal title="Tilpas øvelse" size="lg">
    <template #default="{ close }">
      <form class="assigned-exercise-level-form-modal" autocomplete="off" @submit.prevent="onSubmit(formData, close)">
        <div class="assigned-exercise-level-form-modal__exercise">
          <img
            class="assigned-exercise-level-form-modal__exercise-image"
            :alt="props.exercise.title"
            :src="props.exercise.keyframeUrl"
            loading="lazy"
          />
          <h6 class="assigned-exercise-level-form-modal__exercise-title">{{ props.exercise.title }}</h6>
          <div class="assigned-exercise-level-form-modal__exercise-description">
            {{ props.exercise.description }}
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="form-label" :for="id('title')">Titel</label>
          <input
            :id="id('title')"
            v-model="formData.title"
            :placeholder="props.exercise.title"
            type="text"
            class="form-control"
          />
          <small class="form-text">Efterlad blank for standard</small>
        </div>
        <div class="form-group mb-3">
          <label class="form-label" :for="id('description')">Beskrivelse</label>
          <textarea
            :id="id('description')"
            v-model="formData.description"
            type="text"
            rows="6"
            class="form-control"
            :placeholder="props.exercise.description"
          ></textarea>
          <small class="form-text">Efterlad blank for standard</small>
        </div>
        <div class="form-group mb-3">
          <label class="form-label" :for="id('comment')">Kommentar</label>
          <textarea :id="id('comment')" v-model="formData.comment" type="text" rows="6" class="form-control"></textarea>
        </div>
      </form>
    </template>

    <template #footer="{ close }">
      <MwButton variant="secondary" outline @click="close()">Annuller</MwButton>
      <MwButton variant="primary" @click="onSubmit(formData, close)">Gem</MwButton>
    </template>
  </MwModal>
</template>

<style lang="scss" scoped>
.assigned-exercise-level-form-modal {
  &__exercise {
    display: grid;
    grid-template-areas:
      "image title"
      "image description";
    grid-template-columns: 200px 3fr;
    grid-template-rows: auto 1fr;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e5e5e5;
    gap: 0.5rem 1rem;

    &-image {
      grid-area: image;
      max-width: 100%;
      border-radius: 4px;
    }

    &-title {
      grid-area: title;
      margin: 0;
    }

    &-description {
      grid-area: description;
      white-space: pre-wrap;
    }
  }
}
</style>
