<script setup lang="ts">
import { round } from "lodash";
import { computed, ref, watch } from "vue";

import MwSlider, { type SliderOptions } from "@/components/MwSlider.vue";
import { useMediaControls } from "@/composables";
import type { TrimSelection } from "@/stores";
import { formatDuration } from "@/utils";

const props = defineProps<{ videoUrl: string }>();

const emit = defineEmits<{
  change: [selection: TrimSelection | undefined];
}>();

const videoElement = ref<HTMLVideoElement>();
const { currentTime, duration, pause, seekTo } = useMediaControls(videoElement);

const selection = ref<[number, number]>([0, duration.value]);
const start = computed(() => selection.value[0]);
const end = computed(() => selection.value[1]);

watch(currentTime, (time) => {
  if (time < start.value) {
    seekTo(start.value);
  }

  if (time > end.value) {
    seekTo(start.value);
  }
});

watch(duration, (newDuration) => {
  selection.value = [0, newDuration];
});

const onSlide = (value: number) => {
  pause();
  seekTo(value);
};

const onChange = (values: [number, number]) => {
  const trimStart = round(values[0], 1);
  const trimEnd = round(values[1], 1);

  const hasTrim = trimStart > 0 || trimEnd < round(duration.value, 1);

  emit("change", hasTrim ? { start: trimStart, end: trimEnd } : undefined);
};

const sliderOptions = computed<SliderOptions>(() => {
  return {
    margin: 5,
    step: 0.1,
    range: { min: 0, max: duration.value },
    tooltips: { to: formatDuration }
  };
});
</script>

<template>
  <div class="video-trim-selector">
    <video ref="videoElement" loop controls class="video-trim-selector__video" :src="props.videoUrl"></video>
    <div class="form-group mt-5 mb-3 pb-3 px-3">
      <MwSlider
        v-model="selection"
        :options="sliderOptions"
        :disabled="!duration || (sliderOptions.margin !== undefined && duration <= sliderOptions.margin)"
        @change="(values) => onChange(values)"
        @slide="(value) => onSlide(value)"
      />
      <small class="mt-3 form-text d-inline-block">
        Brug skyderen til at vælge start- og sluttidspunkt for at beskære videoen.
      </small>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-trim-selector {
  &__video {
    display: block;
    width: 100%;
    margin-block: 1rem;
  }
}
</style>
