<script setup lang="ts">
import { ref } from "vue";

import AssignedExerciseLevelFormModal from "@/components/AssignedExerciseLevelFormModal.vue";
import ExerciseVideoModal from "@/components/ExerciseVideoModal.vue";
import MwIcon from "@/components/MwIcon.vue";
import MwIconButton from "@/components/MwIconButton.vue";
import MwImageButton from "@/components/MwImageButton.vue";
import type { AssignedExerciseLevelComputed, AssignedExerciseLevelFormData } from "@/types";

type Props = {
  level?: AssignedExerciseLevelComputed | null;
  isSelected?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  level: null,
  isSelected: false
});

const emit = defineEmits<{
  select: [];
  clear: [];
  update: [data: AssignedExerciseLevelFormData];
}>();

const showFormModal = ref(false);
const showVideoModal = ref(false);
</script>

<template>
  <div
    class="assigned-exercise-level"
    :class="{
      'assigned-exercise-level--is-selected': props.isSelected,
      'assigned-exercise-level--has-exercise': props.level
    }"
    @click.stop="emit('select')"
  >
    <template v-if="props.level">
      <MwImageButton class="assigned-exercise-level__image" @click.stop="showVideoModal = true">
        <img
          :alt="props.level.customizedExercise.title"
          :src="props.level.customizedExercise.keyframeUrl"
          loading="lazy"
        />
        <template #overlay>
          <MwIcon name="play-circle-fill" />
        </template>
      </MwImageButton>
      <div class="assigned-exercise-level__header">
        <h4 class="assigned-exercise-level__title">{{ props.level.customizedExercise.title }}</h4>
        <div class="assigned-exercise-level__actions">
          <MwIconButton
            class="assigned-exercise-level__edit-btn"
            icon="pencil-fill"
            @click.stop="showFormModal = true"
          />
          <MwIconButton class="assigned-exercise-level__remove-btn" icon="trash-fill" @click.stop="emit('clear')" />
        </div>
      </div>
      <ExerciseVideoModal v-if="showVideoModal" v-model="showVideoModal" :exercise="props.level.customizedExercise" />
      <AssignedExerciseLevelFormModal
        v-if="showFormModal"
        v-model="showFormModal"
        :level="props.level"
        :exercise="props.level.originalExercise"
        @submit="(event) => emit('update', event)"
      />
    </template>
    <slot v-else name="placeholder" />
  </div>
</template>

<style lang="scss" scoped>
@use "sass:color";

.assigned-exercise-level {
  display: grid;
  isolation: isolate;
  overflow: hidden;
  border: 1px dashed #aaa;
  border-radius: 6px;
  cursor: pointer;
  gap: 0.5rem;

  &:not(&--has-exercise) {
    place-items: center;
  }

  &--has-exercise {
    grid-template-areas:
      "image"
      "header";
    grid-template-rows: auto 1fr;
    align-items: start;
    border: 1px solid #ddd;
    background-color: white;
  }

  &--is-selected {
    border-color: #0192cf;
    background-color: rgba(#0192cf, 20%);
  }

  &:hover:not(&--is-selected) {
    background-color: rgba(#fff, 70%);
  }

  &__image {
    grid-area: image;
  }

  &__header {
    display: grid;
    grid-area: header;
    grid-auto-columns: 1fr auto;
    grid-auto-flow: column;
    align-items: center;
    padding: 0 0.75rem 0.75rem;
    gap: 0.5rem;
  }

  &__title {
    margin: 0;
    font-size: 1rem;
    word-break: break-word;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    gap: 0.25rem;
    align-items: center;
    align-self: start;
  }

  &__edit-btn {
    color: #495057;
  }

  &__remove-btn {
    color: #dc3545;
  }
}
</style>
