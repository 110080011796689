import { onMounted, onUnmounted, type Ref } from "vue";

export function useConfirmNavigation(shouldConfirm: Ref<boolean>) {
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (shouldConfirm.value) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  onMounted(() => {
    window.addEventListener("beforeunload", handleBeforeUnload, { capture: true });
  });

  onUnmounted(() => {
    window.removeEventListener("beforeunload", handleBeforeUnload, { capture: true });
  });
}
