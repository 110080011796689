<script setup lang="ts">
import MwIcon from "@/components/MwIcon.vue";

interface Props {
  icon: string;
  size?: "sm" | "md" | "lg";
}

const props = withDefaults(defineProps<Props>(), {
  size: "md"
});
</script>

<template>
  <button type="button" class="mw-icon-button">
    <span class="mw-icon-button__content">
      <MwIcon :name="props.icon" :size="props.size" />
    </span>
  </button>
</template>

<style lang="scss" scoped>
.mw-icon-button {
  position: relative;
  margin: 0;
  padding: 4px;
  border: 0;
  border-radius: 50%;
  background: none;
  aspect-ratio: 1 / 1;

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &:is(:hover, :focus-visible, :active) {
    filter: brightness(90%);
    isolation: isolate;
    z-index: 1;
    outline: none;

    &::after {
      content: "";
      position: absolute;
      border-radius: 50%;
      box-shadow: 0 0 0 0.25rem currentcolor;
      inset: 0;
      pointer-events: none;
    }
  }

  &:hover::after {
    opacity: 0.2;
    background-color: currentcolor;
  }

  &:active::after {
    opacity: 0.5;
    background-color: currentcolor;
  }

  &:focus-visible::after {
    opacity: 0.5;
  }

  &__content {
    display: grid;
    place-items: center;
  }
}
</style>
