<script setup lang="ts">
import iconPath from "bootstrap-icons/bootstrap-icons.svg";
import { computed } from "vue";

interface Props {
  name: string;
  size?: "sm" | "md" | "lg";
}

const props = withDefaults(defineProps<Props>(), {
  size: "md"
});

const iconUrl = computed(() => {
  return `${iconPath}#${props.name}`;
});

const iconClasses = computed(() => {
  return ["mw-icon", `mw-icon--${props.size}`];
});
</script>

<template>
  <svg :class="iconClasses" fill="currentColor">
    <use :xlink:href="iconUrl" />
  </svg>
</template>

<style lang="scss" scoped>
.mw-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;

  &--sm {
    width: 0.75rem;
    height: 0.75rem;
  }

  &--lg {
    width: 1.25rem;
    height: 1.25rem;
  }
}
</style>
