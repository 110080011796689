<script setup lang="ts">
interface Props {
  size?: "sm" | "md" | "lg";
}

const props = withDefaults(defineProps<Props>(), {
  size: "md"
});
</script>

<template>
  <span class="mw-spinner" :class="`mw-spinner--size-${props.size}`"></span>
</template>

<style lang="scss" scoped>
.mw-spinner {
  display: inline-block;
  animation: load 700ms infinite ease-in-out;
  border-style: solid;
  border-radius: 50%;
  border-color: var(--color-spinner-track, rgba(0, 0, 0, 0.2));
  border-left-color: var(--color-spinner-indicator, #999);

  &--size {
    &-sm {
      width: 1em;
      height: 1em;
      border-width: 0.15em;
    }

    &-md {
      width: 2em;
      height: 2em;
      border-width: 0.3em;
    }

    &-lg {
      width: 4em;
      height: 4em;
      border-width: 0.45em;
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
