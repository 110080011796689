import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";

import { ExercisesService } from "@/services";
import type { Exercise, ExerciseScope } from "@/types";

interface State {
  loadState: "idle" | "pending" | "finished" | "failed";
  scope: ExerciseScope | undefined;
  exercises: Exercise[];
}

export const useExercisesStore = defineStore("exercises", () => {
  const state = reactive<State>({
    loadState: "idle",
    scope: undefined,
    exercises: []
  });

  const isLoading = computed(() => state.loadState === "pending");

  async function load(scope?: ExerciseScope) {
    try {
      state.loadState = "pending";
      state.exercises = await ExercisesService.getExercises(scope);
      state.scope = scope;
      state.loadState = "finished";
    } catch (error) {
      state.loadState = "failed";
      throw error;
    }
  }

  async function loadOne(id: number) {
    const exercise = await ExercisesService.getExercise(id);

    const existing = getExercise(id);

    if (existing) {
      Object.assign(existing, exercise);
    }

    state.exercises.push(exercise);
  }

  function getExercise(ids: number): Exercise | undefined;
  function getExercise(ids: number[]): Exercise[];
  function getExercise(ids: number | number[]) {
    if (!Array.isArray(ids)) {
      return state.exercises.find((entity) => entity.id === ids);
    }

    return ids.reduce<Exercise[]>((results, id) => {
      const exercise = getExercise(id);

      if (exercise) {
        results.push(exercise);
      }

      return results;
    }, []);
  }

  async function addFavorite(id: number) {
    const exercise = getExercise(id);

    if (!exercise) {
      throw new Error(`Exercise not found: ${id}`);
    }

    const prevFavorite = exercise.favorite;

    try {
      exercise.favorite = true;
      await ExercisesService.favorite(id);
    } catch (error) {
      exercise.favorite = prevFavorite;
      throw error;
    }
  }

  async function removeFavorite(id: number) {
    const exercise = getExercise(id);

    if (!exercise) {
      throw new Error(`Exercise not found: ${id}`);
    }

    const prevFavorite = exercise.favorite;

    try {
      exercise.favorite = false;
      await ExercisesService.unfavorite(id);
    } catch (error) {
      exercise.favorite = prevFavorite;
      throw error;
    }
  }

  return {
    ...toRefs(state),
    isLoading,
    load,
    loadOne,
    getExercise,
    addFavorite,
    removeFavorite
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExercisesStore, import.meta.hot));
}
