<script setup lang="ts">
import { pick } from "lodash-es";
import { reactive, watch } from "vue";

import { useScopedId } from "@/composables";
import type { AssignedExercise, AssignedExerciseFormData, BarometerTemplate } from "@/types";

interface Props {
  assignedExercise: AssignedExercise;
  barometerTemplate?: BarometerTemplate | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  barometerTemplate: undefined
});

const emit = defineEmits<{
  change: [data: AssignedExerciseFormData];
}>();

const formData = reactive<AssignedExerciseFormData>(
  pick(props.assignedExercise, ["sets", "repetitions", "registerVas", "paused"])
);

watch(formData, (data) => {
  emit("change", data);
});

watch(
  () => props.assignedExercise,
  (assignedExercise) => {
    Object.assign(
      formData,
      pick(assignedExercise, ["sets", "repetitions", "registerVas", "paused"]) satisfies AssignedExerciseFormData
    );
  },
  { deep: true }
);

const { id } = useScopedId<keyof AssignedExerciseFormData>();
</script>

<template>
  <form autocomplete="off" class="assigned-exercise-form" @submit.prevent>
    <div class="form-floating">
      <input :id="id('sets')" v-model="formData.sets" type="number" class="form-control" min="1" />
      <label :for="id('sets')">Sæt</label>
    </div>
    <div class="form-floating">
      <input :id="id('repetitions')" v-model="formData.repetitions" type="number" class="form-control" min="1" />
      <label :for="id('repetitions')" class="form-label">Repetitioner</label>
    </div>
    <div v-if="props.barometerTemplate" class="form-group">
      <div class="form-check form-switch">
        <label :for="id('registerVas')" class="form-check-label">
          <span class="badge" :style="{ backgroundColor: props.barometerTemplate.color }">
            {{ props.barometerTemplate.title }}
          </span>
        </label>
        <input
          :id="id('registerVas')"
          v-model="formData.registerVas"
          class="form-check-input"
          type="checkbox"
          role="switch"
        />
      </div>

      <div class="form-check form-switch">
        <label :for="id('paused')" class="form-check-label">I bero</label>
        <input :id="id('paused')" v-model="formData.paused" class="form-check-input" type="checkbox" role="switch" />
      </div>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.assigned-exercise-form {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 120px 120px 1fr;
  gap: 0.75rem;
  align-items: center;
}
</style>
