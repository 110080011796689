import { createPinia } from "pinia";

import { createResetState } from "./plugins";

export const pinia = createPinia().use(createResetState());

export * from "./barometer-templates.store";
export * from "./exercise-editor.store";
export * from "./exercise-finder.store";
export * from "./exercise-levels.store";
export * from "./exercise-program-editor.store";
export * from "./exercises.store";
export * from "./tag-categories.store";
