import type { CancellablePromise } from "real-cancellable-promise";

import { get, patch } from "@/api";
import type { ExerciseProgram } from "@/types";

export function getExerciseProgram(id: number): CancellablePromise<ExerciseProgram> {
  return get(`/exercise_programs/${id}`);
}

export function updateExerciseProgram(exerciseProgram: ExerciseProgram): CancellablePromise<ExerciseProgram> {
  return patch(`/exercise_programs/${exerciseProgram.id}`, { json: exerciseProgram });
}
