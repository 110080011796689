<script setup lang="ts">
import { Modal } from "bootstrap";
import { onMounted, ref, useSlots, watch } from "vue";

import MwIconButton from "@/components/MwIconButton.vue";

interface Props {
  modelValue?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  title?: string;
  disableAutoFocusInput?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  size: "md",
  title: undefined,
  disableAutoFocusInput: false
});

const emit = defineEmits<{
  shown: [];
  hidden: [];
  "update:modelValue": [value: boolean];
}>();

const slots = useSlots();

const modalElement = ref<HTMLElement>();

onMounted(() => {
  if (!modalElement.value) {
    return;
  }

  const modalInstance = new Modal(modalElement.value);

  let restoreActiveElement: HTMLElement | undefined;

  modalElement.value.addEventListener("show.bs.modal", () => {
    if (document.activeElement instanceof HTMLElement) {
      restoreActiveElement = document.activeElement;
    }
  });

  modalElement.value.addEventListener("shown.bs.modal", () => {
    if (!props.disableAutoFocusInput) {
      modalElement.value?.querySelector<HTMLElement>(":read-write")?.focus();
    }

    emit("shown");
    emit("update:modelValue", true);
  });

  modalElement.value.addEventListener("hidden.bs.modal", () => {
    if (restoreActiveElement) {
      restoreActiveElement.focus();
    }

    emit("hidden");
    emit("update:modelValue", false);
  });

  watch(
    () => props.modelValue,
    (modelValue) => {
      modelValue ? modalInstance.show() : modalInstance.hide();
    },
    { immediate: true }
  );
});

const close = () => {
  if (modalElement.value) {
    Modal.getInstance(modalElement.value)?.hide();
  }
};
</script>

<template>
  <Teleport to="body">
    <div ref="modalElement" class="modal fade mw-modal" tabindex="-1" :aria-label="props.title">
      <div class="modal-dialog" :class="[`modal-${props.size}`, `modal-fullscreen-${props.size}-down`]">
        <div class="modal-content">
          <div class="modal-header">
            <h1 v-if="props.title" class="modal-title h5">{{ props.title }}</h1>
            <MwIconButton class="mw-modal__close-btn" icon="x-lg" size="lg" aria-label="Luk" @click="close()" />
          </div>
          <div class="modal-body">
            <slot name="default" v-bind="{ close }" />
          </div>
          <div v-if="slots.footer" class="modal-footer">
            <slot name="footer" v-bind="{ close }" />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.mw-modal {
  &__close-btn {
    color: #6c757d;
  }
}
</style>
