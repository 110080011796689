let scopeCounter = -1;

export function useScopedId<T extends string>() {
  const scope = ++scopeCounter;

  const id = (name: T) => {
    return `${name}-${scope}`;
  };

  return { id };
}
