<script setup lang="ts">
import { ref } from "vue";

const props = withDefaults(defineProps<{ delay?: number }>(), {
  delay: 400
});

const show = ref(!props.delay);

setTimeout(() => (show.value = true), props.delay);
</script>

<template>
  <slot v-if="show" />
</template>
