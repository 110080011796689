import { cloneDeep } from "lodash-es";
import type { PiniaPlugin } from "pinia";

export function createResetState(): PiniaPlugin {
  return ({ store }) => {
    const initialState = cloneDeep(store.$state);

    store.$reset = () => {
      store.$patch(($state) => {
        Object.assign($state, initialState);
      });
    };
  };
}
