import type { Directive } from "vue";

import { registerDropzone, unregisterDropzone, type UseDropzoneConfig } from "@/composables";

export const vDropzone: Directive<HTMLElement, Partial<UseDropzoneConfig>> = {
  mounted(el, { value: options }) {
    registerDropzone(el, options);
  },
  unmounted(el) {
    unregisterDropzone(el);
  }
};
