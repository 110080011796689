import TomSelect from "tom-select";
import type { TomInput } from "tom-select/dist/types/types";

document.querySelectorAll<TomInput>(".filtered-select").forEach((el) => {
  new TomSelect(el, {
    dropdownParent: "body",
    maxOptions: undefined,
    plugins: ["remove_button"],
    allowEmptyOption: true,
    render: { no_results: false }
  });
});
