import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";

import { useExercisesStore } from "@/stores";
import type { Exercise } from "@/types";

interface State {
  selectedExercise: Exercise | undefined;
}

export const useExerciseLevelsStore = defineStore("exerciseLevels", () => {
  const state = reactive<State>({
    selectedExercise: undefined
  });

  const exercisesStore = useExercisesStore();

  const progressingExercises = computed(() => {
    if (!state.selectedExercise) {
      return [];
    }

    return exercisesStore.getExercise(state.selectedExercise.progressingIds);
  });

  const regressingExercises = computed(() => {
    if (!state.selectedExercise) {
      return [];
    }

    return exercisesStore.getExercise(state.selectedExercise.regressingIds);
  });

  function select(exercise: Exercise) {
    state.selectedExercise = exercise;
  }

  function clear() {
    state.selectedExercise = undefined;
  }

  return {
    ...toRefs(state),
    progressingExercises,
    regressingExercises,
    select,
    clear
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExerciseLevelsStore, import.meta.hot));
}
