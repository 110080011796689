import { uniq } from "lodash-es";
import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";

import { Cancellation } from "@/api";
import { useSingleton } from "@/composables";
import { ExercisesService } from "@/services";
import { useExercisesStore, useTagCategoriesStore } from "@/stores";
import type { Exercise, ExerciseFilter } from "@/types";

interface State {
  searchState: "idle" | "pending" | "finished" | "failed";
  filter: ExerciseFilter | undefined;
  exerciseIds: number[] | undefined;
  limit: number | undefined;
}

const searchSingleton = useSingleton(ExercisesService.search);

export const defaultFilter: Readonly<ExerciseFilter> = {
  query: "",
  tags: [],
  onlyFavorites: false,
  onlyPersonal: false
};

export const useExerciseFinderStore = defineStore("exerciseFinder", () => {
  const state = reactive<State>({
    searchState: "idle",
    filter: undefined,
    exerciseIds: undefined,
    limit: 5000
  });

  const exercisesStore = useExercisesStore();
  const tagCategoriesStore = useTagCategoriesStore();

  const isSearching = computed(() => state.searchState === "pending");

  const exercises = computed(() => {
    if (!state.filter || state.exerciseIds === undefined) {
      return exercisesStore.exercises;
    }

    return state.exerciseIds.reduce<Exercise[]>((results, id) => {
      const exercise = exercisesStore.getExercise(id);

      if (exercise) {
        results.push(exercise);
      }

      return results;
    }, []);
  });

  const matchingTags = computed(() => {
    const exercisesTags = exercises.value.reduce<string[]>((tags, exercise) => {
      tags.push(...exercise.tags);
      return tags;
    }, []);

    return uniq(exercisesTags);
  });

  const nonMatchingTags = computed(() => {
    return tagCategoriesStore.tags.filter((tag) => {
      return !matchingTags.value.includes(tag);
    });
  });

  async function search(filter: ExerciseFilter) {
    const prevState = state.searchState;

    try {
      state.searchState = "pending";
      state.exerciseIds = await searchSingleton(filter, exercisesStore.scope);
      state.filter = filter;
      state.searchState = "finished";
    } catch (error) {
      if (error instanceof Cancellation) {
        state.searchState = prevState;
        return;
      }

      state.searchState = "failed";
      throw error;
    }
  }

  function reset() {
    state.filter = undefined;
    state.exerciseIds = undefined;
  }

  return {
    ...toRefs(state),
    search,
    reset,
    isSearching,
    exercises,
    matchingTags,
    nonMatchingTags
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExerciseFinderStore, import.meta.hot));
}
