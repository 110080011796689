<script setup lang="ts">
import NoUiSlider, { type Options } from "nouislider";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";

export type SliderOptions = Partial<Options>;

interface Props {
  modelValue?: number[];
  options?: SliderOptions;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  options: () => ({}),
  disabled: false
});

const emit = defineEmits<{
  slide: [value: number];
  change: [values: [number, number]];
  "update:modelValue": [values: [number, number]];
}>();

const element = ref<HTMLElement>();

onMounted(() => {
  if (!element.value) {
    return;
  }

  const slider = NoUiSlider.create(element.value, {
    ...props.options,
    start: props.modelValue,
    connect: true,
    animate: false,
    tooltips: true,
    range: {
      min: 0,
      max: 100
    }
  });

  slider.on("change", (values) => {
    const [min, max] = values.map((value) => parseFloat(`${value}`));

    emit("change", [min, max]);
    emit("update:modelValue", [min, max]);
  });

  slider.on("slide", (values, handleNumber) => {
    emit("slide", parseFloat(`${values[handleNumber]}`));
  });

  watch(
    () => props.options,
    (options) => slider.updateOptions(options, false)
  );

  watch(
    () => props.modelValue,
    (value) => {
      if (value) {
        slider.set(value);
      }
    },
    { deep: true }
  );

  watch(
    () => props.disabled,
    (disabled) => {
      disabled ? slider.disable() : slider.enable();
    },
    { immediate: true }
  );

  onBeforeUnmount(() => slider.destroy());
});
</script>

<template>
  <div ref="element" class="mw-slider"></div>
</template>

<style lang="scss">
@import "nouislider/dist/nouislider.css";

.mw-slider {
  /* stylelint-disable-next-line selector-class-pattern */
  .noUi-tooltip {
    color: #6c757d;
    font-size: 0.75rem;
  }
}
</style>
